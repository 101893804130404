.css-loader {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: 12%;
    left: 50%;
    translate: -50% 0;
}
.loader-message {
    padding-right: 13px;
}
.loader-message h4 {
    margin: 0;
    white-space: nowrap;
}

.dot-flashing {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    /* background-color: #9880ff; */
    background-color: black;
    color: black;
    animation: dotFlashing .5s infinite linear alternate;
    animation-delay: .2s;
    margin-bottom: 4px;
  }
  
  .dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
  .dot-flashing::before {
    left: -10px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: black;
    color: black;
    animation: dotFlashing .5s infinite alternate;
    animation-delay: 0s;
  }
  
  .dot-flashing::after {
    left: 10px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: black;
    /* color: #9880ff; */
    color: black;;
    animation: dotFlashing .5s infinite alternate;
    animation-delay: .4s;
  }
  
  @keyframes dotFlashing {
    0% {
      /* background-color: #9880ff; */
      background-color: black;
    }
    50%,
    100% {
      /* background-color: #ebe6ff; */
      background-color:#bac2d0;
    }
  }