.App {
  text-align: center;
  font-size: 18px;
  font-family: "Merriweather","Georgia",Cambria,"Times New Roman",Times,serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  line-height: 1.625;
}

h1,h2,h3,h4,h5,h6 {
  font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header, 
.posteditor-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;  
}

/* APP STYLES TO FIT POSTS TEXT EDITOR */
/* .App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
  text-align: center;
} */


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* STYLES FOR POSTS TEXT EDITOR START */
.posteditor-header {
  min-height: 5vh;
  /* margin-bottom: 5vh; */
  text-align: center;

}
.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
  /* min-height: 20rem */
}
.editor-class {
  /* background-color:ivory; */
  background-color:whitesmoke;
  padding: 1rem;
  border: 1px solid #ccc;
  /* IMPORTANT: MAKE THE TEXT IN THE EDITOR FONT SIZE 18 */
  font-size: 18px;
  /* font-family: 'Hoefler Text', Georgia, serif; */
}

.toolbar-class {
  border: 1px solid #ccc !important;
  position: sticky;
  z-index: 80;
  top: 0;
}

.postEditor {
  /* margin-left: 4rem;
  margin-right: 4rem; */
  /* margin-left: 8rem; */
  /* margin-right: 8rem; */
  padding-bottom: 2rem;
  max-width: 736px;
  margin-left: auto;
  margin-right: auto;
}

/* Prevents the upload image box to overflow the editor container */
.rdw-image-modal, .rdw-embedded-modal{
  left: -60px
}
/* Hides the image size options in the post editor */
.rdw-image-modal-size, .rdw-embedded-modal-size {
  visibility: hidden;;
}

.crud-buttons {
  position:relative;
}
.crud-buttons p {
  margin: 0;
}
/* STYLES FOR POSTS TEXT EDITOR END */


.Nav-addon {
  background-color:  #002984;
  /* background-color: black; */
  height: 0.8rem;;
}

.main {
  margin-top: 20px;
}

.image, img {
  max-width: 100%;
}

.resize-initial {
  width: 100%;
  z-index: 10;
}

.resize-initial img {
  cursor: pointer;
  max-height: 100%;
}

.resize-container {
  height: 100%;
  width: 100vw;
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #fafafa; */
  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 20px;
  /* visibility: hidden; */
  /* opacity: 0; */
  /* transition: opacity ease 1s;  */
}

.resize-show {
  visibility: visible;
  opacity: 1;
}

.video {
  box-sizing: border-box;
  max-width: 100%;
  border: solid 1px grey;
}

iframe {
  width: 100%;
  /* height: 225px; */
  height: auto;
}
/* hide an image when its source code does not load */
img:not([src]):not([srcset]) {
  visibility: hidden;
}


.home,
.authorPage {
  display: grid;
  grid-template-areas:
  "a"
  "b"
  "c";
  gap: 1.5rem 1.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.mainPost {
  grid-area: a;
}

.featuredPosts {
  grid-area: b;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem 1.5rem;
}

.remainderPosts {
  grid-area: c;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem 1.5rem;
}

.postPage,
.postListPage,
.authorListPage,
.aboutPage {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: left;
}

.authorPage {
  margin-top: 2rem;
}

.postPage .authorInfo {
  display: flex;
}

.postPage .authorInfo > div:nth-child(2) {
  padding-left: 12px;
}

.postPage .authorInfo a,
.bio-links a {
  text-decoration: none;
  color: #663399;
}

.postPage .authorInfo .editLink {
  flex-grow: 1;
  text-align: right;
}

.postPage .cover-image {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 28px;
}

/* centralize the body images of blog posts */
.postSection > :not(.authorInfo) img:not(.image) {
  max-width: 100%;
  margin: auto;
  display: block;
}

.standardSize {
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
}

.errorField {
  margin: 0;
  color: red;
}

/* .reponsive: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
); */

:is(.postLinks, .authorLinks, .profile-container) ul {
  padding: 0;
}

:is(.postLinks, .authorLinks) li {
  list-style-type: none;
  /* margin-bottom: 2rem; */
  font-size: 1rem;
}

.postLinks article {
  margin-bottom: 32px;
}

.postLinks h2 {
  margin-bottom: 8px;
}
.postLinks span {
  font-size: 12.8px;
}

.postLinks p {
  font-size: 16px;
}

:is(.postLinks, .authorLinks) a {
  text-decoration: none;
  color: #005b99;
}

.profile-container {
  margin: 0 auto;
  max-width: 736px;
}

.profile-container ul {
  text-align: left;
  margin-top: 0;
}

.profile-container ul a {
  text-decoration: none;
  color: #005b99;
  font-weight: 500;
}

.profile-container li {
  list-style-type: none;
  margin-bottom: 10px;
}

.profile-container li span {
  font-size: 14px;;
}

.profile-container h3 {
  margin-bottom: 8px;
}


/* MEDIUM SCREEN */
@media screen and (max-width: 960px) {
  .home,
  .authorPage {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .featuredPosts, .remainderPosts {
    grid-template-columns: 1fr 1fr
  } 
 }

/* SMALL SCREEN */
@media screen and (max-width: 540px) {
  .featuredPosts, 
  .remainderPosts {
    grid-template-columns: 1fr
  } 
  .postPage,
  .postListPage,
  .authorListPage {
    margin-left: 4px;
    margin-right: 4px;
  }
  .postEditor {
    max-width: 736px;
    margin-left: 0rem;
    margin-right: 0rem;
  }

 }

/* CODE STYLING START */
pre {
  /* This line ensures that the code snippets do not overflow their respective container */
  white-space: pre-wrap;
  padding: 1rem;
  background-color: rgba(242, 242, 242, 1);
  font-family: Menlo, Monaco, "Courier New", Courier, monospace;
  font-size: 16px !important;
  overflow-x: scroll;
  line-height: 1.18;
  margin-top: 40px;
}

code {
  font-size: 16px;
  background-color: rgba(242, 242, 242, 1);
  /* background-color: #f5f5f5 */
}
/* CODE STYLING END */

.superFancyBlockquote, blockquote {
  /* font-family: 'Hoefler Text', Georgia, serif; */
  font-style: italic;
}

/* MODAL STYLES STRART */
#modal {
  font-size: 16px;
  flex-direction: column;
  margin: 0;
  border-radius: 5px;
  position: absolute;
  z-index: 200;
  left: 50%;
  translate: -50% 0;
  padding: 3px 12px;
  background-color: #607d8b;
  color: white;
}

#modal li {
  list-style-type: none;
}
/* MODAL STYLES END */


.disqus-section {
  margin-top: 70px;
}





