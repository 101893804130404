
.nav {
    /* height: 55px; */
    width: 100%;
    /* background-color: #4d4d4d; */
    background-color: #3f51b5;
    position: relative;
    text-align: left;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.nav > .nav-header {
    display: inline;
}

.nav > .nav-header > .nav-title {
    display: inline-block;
    font-size: 22px;
    padding: 8px 10px 8px 25px;
}

.nav > .nav-header > .nav-title a {
    color: black;
    text-decoration: none;
}

.nav > .nav-btn {
    display: none;
}

/* new */
.nav > .nav-btn label {
    cursor: pointer;
}

.nav > .nav-links {
    display: inline;
    float: right;
    font-size: 18px;
}

.nav > .nav-links > a {
    display: inline-block;
    padding: 10px 30px 10px 30px;
    text-decoration: none;
    color: #f5f5f7;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
}

.nav > .nav-links > a:hover {
    background-color: rgba(0, 0, 0, 0.3);
    color: #efefef;
}

.nav > #nav-check {
    display: none;
}

@media (max-width: 960px) {
    .nav > .nav-links > a {
        padding-left: 25px;
        padding-right: 25px;
    }
}

@media (max-width:760px) {
    .nav > .nav-header {
        display: block;
        text-align: center;
    }
    .nav .nav-title {
        padding: 8px 10px 8px 10px !important;
    }
    .nav > .nav-btn {
        display: inline-block;
        position: absolute;
        top: 0px;
    }
    .nav > .nav-btn > label {
        /* display: inline-block; */
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 45px;
        /* height: 50px; */
        /* padding: 13px; */
        padding: 14px 5px 4px 5px;
    }
    .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
        background-color: rgba(0, 0, 0, 0.3);
    }
    .nav > .nav-btn > label > span {
        display: block;
        width: 25px;
        height: 8px;
        border-top: 2px solid #eee;
        margin: 0 auto;
    }
    .nav > .nav-links {
        position: absolute;
        display: block;
        width: 100%;
        background-color: #333;
        /* background-color: #3f51b5; */
        height: 0px;
        transition: all 0.3s ease-in;
        overflow-y: hidden;
        top: 50px;
        left: 0px;
        float: none;
    }
    .nav > .nav-links > a {
        display: block;
        /* display: flex; */
        /* width: 100%; */
        padding-left: 20px;
        /* padding: 0px; */
    }
    .nav > #nav-check:not(:checked) ~ .nav-links {
        height: 0px;
        z-index: 2;
    }
    .nav > #nav-check:checked ~ .nav-links {
        height: calc(100vh - 50px);
        overflow-y: auto;
        z-index: 2;
    }
}

.navLogo {
    min-width: 2px !important;
    width: 2px !important;
    height: 9px !important;
    margin: 2px 1px 0px 1px !important;
    border-radius: 2px !important;
}

.navLogo .MuiTypography-caption {
    font-size: 7px;
}